import React from "react";

const TextBlock = ({ fontSize, color, textValue, style, className }) => {
	const customStyle = {
		fontSize,
		color,
		...style,
	};

	return (
		<p className={className} style={customStyle}>
			{textValue}
		</p>
	);
};

export default TextBlock;
