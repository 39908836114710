import React from "react";

import "./BackDrop.scss";

const BackDrop = ({ onClick, children }) => {
	return (
		<div onClick={() => onClick(false)} className="overlay__backdrop--dark">
			{children}
		</div>
	);
};

export default BackDrop;
