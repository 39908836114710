import React from "react";
import redirect_urls_data from "__root/configs/redirect_urls_data.js";
import lp_configs from "__root/configs/lp_configs.js";

//parsing the trackers to the correct format
const _parseUrlParams = (params) => {
	const filteredParams = [];

	for (const key in params) {
		if (params[key]) {
			filteredParams.push(`${key}=${params[key]}`);
		}
	}

	return filteredParams.join("&");
};

//redirects to the game depending on the sales type
const getRedirectUrl = (urlParams) => {
	const { game, st, trackers } = urlParams;

	const games = redirect_urls_data.games;

	if (!games[game]) return;

	const paramString = _parseUrlParams(trackers);

	switch (st) {
		case "mb":
			return games[game].mb_redirect_link;
		case "rt":
			return games[game].rt_redirect_link;
		case "aff":
			return paramString
				? `${games[game].redirect_link}?${paramString}`
				: games[game].redirect_link;
		default:
			return games[game].redirect_link;
	}
};

//gets the ad redirect url depending on sales type and type(pop under or exit pop-up)
const getAdUrl = (type, st) => {
	const salesTypes = ["aff", "rt", "mb"];
	const ad_urls = redirect_urls_data.ad_urls;

	if (!salesTypes.includes(st)) {
		return ad_urls[type].aff;
	}

	return ad_urls[type][st];
};

//functionality for pop under
const redirectWithAd = (redirect_url, st) => {
	const adUrl = getAdUrl("pu", st);
	const currentUrl = redirect_url ?? window.location.href;

	window.open(currentUrl, "_blank");
	window.location.replace(adUrl);
};

const mapComponents = (Component, componentsProps) => {
	return componentsProps.map((props, index) => (
		<Component key={index} {...props} />
	));
};

const changeFaviconAndTitle = (gameAbbreviation) => {
	let favicon_link = document.querySelector("link[rel~='icon']");

	const checkedGameAbbreviation =
		gameAbbreviation in redirect_urls_data.games ? gameAbbreviation : "mrpg";

	if (!favicon_link) {
		favicon_link = document.createElement("link");
		favicon_link.rel = "icon";
		favicon_link.type = "image/png";
		document.getElementsByTagName("head")[0].appendChild(favicon_link);
	}

	favicon_link.href = `/favicons/${checkedGameAbbreviation}.png`;

	const game = redirect_urls_data.games[checkedGameAbbreviation];

	document.title = game.title;
};

const getDeviceType = (width, height) => {
	if (width < 1024) {
		return width > height ? "landscape" : "mobile";
	}
	return "desktop";
};

const getNo = (no, numbers) => {
	return numbers.includes(`n${no}`) ? `n${no}` : numbers[0];
};

const getGame = (game, games) => {
	if (games[game]) {
		return game;
	} else {
		return "aa";
	}
};

const _buildAssetPathForVariantLp = (width, height, componentName, url_params) => {
	const { game, lang, type, no } = url_params;

	const valid_game = getGame(game, lp_configs.variant_lps[componentName]);

	const {
		types,
		langs,
		numbers = null,
		supported_devices,
	} = lp_configs.variant_lps[componentName][valid_game];

	const validated_language = langs.includes(lang) ? lang : langs[0];
	const validated_type = types.includes(type) ? type : types[0];
	const validated_no = numbers ? `${getNo(no, numbers)}/` : "";
	let device_type = getDeviceType(width, height);

	if (device_type === "landscape" && !supported_devices.includes("landscape")) {
		device_type = "desktop";
	}

	return `./assets/${componentName}/${valid_game}/${validated_no}${validated_type}/${validated_language}/${device_type}/`;
};

const _buildAssetPath = (width, height, componentName, url_params) => {
	const { game, lang, type } = url_params;

	const { games, types, langs, supported_devices } = lp_configs[componentName];
	const validated_language = langs.includes(lang) ? lang : langs[0];
	const validated_game = games.includes(game) ? game : games[0];
	const validated_type = types.includes(type) ? type : types[0];
	let device_type = getDeviceType(width, height);

	if (device_type === "landscape" && !supported_devices.includes("landscape")) {
		device_type = "desktop";
	}
	return `./assets/${componentName}/${validated_game}/${validated_type}/${validated_language}/${device_type}/`;
};

//building the path for the assets for the landing page
const buildAssetPath = (width, height, componentName, url_params) => {
	if (lp_configs[componentName]) {
		return _buildAssetPath(width, height, componentName, url_params);
	} else if (lp_configs.variant_lps[componentName]) {
		return _buildAssetPathForVariantLp(width, height, componentName, url_params);
	} else {
		return;
	}
};

const getUrlParams = () => {
	const urlParams = new URLSearchParams(window.location.search);

	return {
		baseAssetPath: null,
		windowSize: {},
		cat: urlParams.get("cat"),
		game: urlParams.get("game"),
		lang: urlParams.get("lang"),
		st: urlParams.get("st"),
		type: urlParams.get("type"),
		pu: urlParams.get("pu"),
		ep: urlParams.get("ep"),
		no: urlParams.get("no"),
		trackers: {
			tc1: urlParams.get("tc1"),
			tc2: urlParams.get("tc2"),
			tc3: urlParams.get("tc3"),
			tc4: urlParams.get("tc4"),
			tc5: urlParams.get("tc5"),
			tc6: urlParams.get("tc6"),
			tc7: urlParams.get("tc7"),
			tc8: urlParams.get("tc8"),
			tc9: urlParams.get("tc9"),
			tc10: urlParams.get("tc10"),
			source: urlParams.get("source"),
			campaign: urlParams.get("campaign"),
			ref_id: urlParams.get("ref_id"),
			noagev: urlParams.get("noagev"),
			no: urlParams.get("no"),
		},
	};
};

const getWeightedRandom = (items) => {
	const totalWeight = items.reduce((sum, item) => sum + item.weight, 0);
	let randomNum = Math.random() * totalWeight;

	for (const item of items) {
		if (randomNum < item.weight) {
			return item;
		}
		randomNum -= item.weight;
	}
};

function getUniqueRandomVideos(videos, count = 5) {
	const selectedVideos = [];
	const selectedUrls = new Set();

	while (selectedVideos.length < count) {
		const video = getWeightedRandom(videos);

		if (!selectedUrls.has(video.name)) {
			selectedVideos.push(video);
			selectedUrls.add(video.name);
		}
	}

	return selectedVideos;
}

export {
	getRedirectUrl,
	redirectWithAd,
	mapComponents,
	changeFaviconAndTitle,
	buildAssetPath,
	getAdUrl,
	getNo,
	getUrlParams,
	getGame,
	getDeviceType,
	getUniqueRandomVideos
};
