import React, { lazy } from "react";

import { useParams } from "react-router-dom";
import useWindowSize from "__src/components/hooks/screensize-hook";
import { getDeviceType } from "__src/components/utils/helpers.js";
import { GlobalContextProvider } from "__src/components/store/GlobalContext.jsx";
import ExitPopUp from "__src/components/ui/ExitPopUp/ExitPopUp.jsx";

const DefaultHome = lazy(() =>
	import("__src/LandingPages/DefaultHome/DefaultHome.jsx")
);
const LoginLP = lazy(() => import("../LandingPages/Login/LoginLP.jsx"));
const PaymentLP = lazy(() => import("../LandingPages/Payment/PaymentLP.jsx"));

const HomeLP = lazy(() => import("__src/LandingPages/HomeLP/HomeLP.jsx"));
const VideoLp = lazy(() => import("__src/LandingPages/VideoLP/VideoLP.jsx"));
const BackgroundImageWithButtonLp = lazy(() =>
	import(
		"__src/LandingPages/BackgroundImageWithButtonLp/BackgroundImageWithButtonLp.jsx"
	)
);
const CustomizeLP = lazy(() =>
	import("__src/LandingPages/CustomizeLP/CustomizeLP.jsx")
);
const ImageLP = lazy(() => import("__src/LandingPages/ImageLP/ImageLP.jsx"));

const VideoWeight = lazy(() =>
	import("__src/LandingPages/VideoWeight/VideoWeight.jsx")
);

const ClickToSpeedUp = lazy(() =>
	import("__src/LandingPages/ClickToSpeedUp/ClickToSpeedUp.jsx")
);
//route_name: Component
const components = {
	video_weight: VideoWeight,
	video_lp: VideoLp,
	home_lp: HomeLP,
	base_lp: BackgroundImageWithButtonLp,
	customize_girl: CustomizeLP,
	login_lp: LoginLP,
	payment_lp: PaymentLP,
	image_lp: ImageLP,
	speed_video: ClickToSpeedUp,
};

const DynamicComponentDelivery = () => {
	const { componentName } = useParams();
	const ComponentForRender = components[componentName];

	const windowSize = useWindowSize();

	return (
		<GlobalContextProvider componentName={componentName}>
			<ExitPopUp />

			{ComponentForRender ? (
				<ComponentForRender
					deviceType={getDeviceType(windowSize.width, windowSize.height)}
				/>
			) : (
				<DefaultHome />
			)}
		</GlobalContextProvider>
	);
};

export default DynamicComponentDelivery;
