import { useState, useCallback } from "react";

export const useAuth = () => {
	const [isLoggedin, setIsLoggedin] = useState(false);
	const [userId, setUserId] = useState(null);

	const login = useCallback((status, uId) => {
		setIsLoggedin(status);
		setUserId(uId);
	}, []);

	return { isLoggedin, userId, login };
};
