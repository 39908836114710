/**
 * The configuration object for various landing pages (LPs).
 * Each LP has properties defining its behavior and supported options.
 *
 * Properties for each LP:
 * - `types`: Specifies whether the LP is safe for work or not. Possible values:
 *   - `"n"`: Not safe for work.
 *   - `"s"`: Safe for work.
 * - `langs`: Defines the languages supported by the LP (e.g., `"en"`, `"fr"`).
 * - `games`: Lists the games for which this LP is applicable.
 * - `numbers`: Optional. When having identical LP's for one game, use this property,
 * each of the numbers should have the same properties depending on the lp
 * Ex: `base_lp` Indicates the supported positions for buttons on the LP.
 * - `n1`, `n2`, etc.: Configuration for specific button positions.
 *   - `isPulsing`: Whether the button has a pulsing animation (`true` or `false`).
 *   - `mobile`: Defines the style for mobile view.
 *     - `top`: Vertical position (e.g., `"60%"`).
 *     - `left`: Horizontal position (e.g., `"50%"`).
 *     - `height`: Height of the button (e.g., `"10vh"`).
 *   - `desktop`: Defines the style for desktop view.
 *     - `top`: Vertical position (e.g., `"65%"`).
 *     - `left`: Horizontal position (e.g., `"50%"`).
 *     - `height`: Height of the button (e.g., `"18vh"`).
 *   - `secondButton` (optional): Configuration for a secondary button at this position.
 *     - `isPulsing`: Whether the button has a pulsing animation (`true` or `false`).
 *     - `mobile`: Styles for the secondary button in mobile view.
 *     - `desktop`: Styles for the secondary button in desktop view.
 */

const lp_configs = {
	login_lp: {
		supported_devices: ["desktop", "mobile"],
		types: ["s"],
		langs: ["en"],
		games: ["hh"],
	},
	payment_lp: {
		supported_devices: ["desktop", "mobile"],
		types: ["s"],
		langs: ["en"],
		games: ["hh"],
	},
	// LP with only a video
	video_lp: {
		supported_devices: ["desktop", "mobile"],
		types: ["n", "s"],
		langs: ["en", "fr"],
		games: ["hh", "psh"],
	},

	// LP with various components (not easily reusable)
	home_lp: {
		supported_devices: ["desktop", "mobile"],
		types: ["n", "s"],
		langs: ["en", "fr"],
		games: ["hh"],
	},

	customize_girl:{
		supported_devices: ["desktop", "mobile"],
		types: ["n", "s"],
		langs: ["en", "fr"],
		games: ["hh"],
	},

	video_weight:{
		supported_devices: ["desktop", "mobile"],
		types: ["n", "s"],
		langs: ["en", "fr"],
		games: ["hh"],
	},

	speed_video:{
		supported_devices: ["desktop", "mobile"],
		types: ["n", "s"],
		langs: ["en", "fr"],
		games: ["hh"],
	},

	//LP's which have variations -> (same design, different assets or data)
	variant_lps: {
		image_lp:{
			cf:{
				supported_devices: ["desktop", "mobile"],
				langs: ["en", "fr"],
				types: ["n", "s"],
				numbers: ["n1", "n2", "n3", "n4", "n5"],
			},
		},

	// LP with a background image and one or two buttons at various positions.
		base_lp: {
			aa: {
				supported_devices: ["desktop", "mobile"],
				langs: ["en", "fr"],
				types: ["s"],
				numbers: ["n1", "n2", "n3", "n4", "n5"],
				n1: {
					mobile: {
						top: "60%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "65%",
						left: "50%",
						height: "18vh",
					},
					secondButton: {
						mobile: {
							top: "68%",
							left: "50%",
							height: "10vh",
						},
						desktop: {
							top: "80%",
							left: "50%",
							height: "15vh",
						},
					},
				},

				// Button configuration for position n2
				n2: {
					mobile: {
						top: "75%",
						left: "50%",
						height: "8vh",
					},
					desktop: {
						top: "70%",
						left: "49%",
						height: "16vh",
					},
				},

				// Button configuration for position n3
				n3: {
					mobile: {
						top: "70%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "80%",
						left: "50%",
						height: "20vh",
					},
				},

				// Button configuration for position n4
				n4: {
					mobile: {
						top: "62%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "80%",
						left: "50%",
						height: "20vh",
					},
				},

				// Button configuration for position n5
				n5: {
					mobile: {
						top: "85%",
						left: "50%",
						height: "8vh",
					},
					desktop: {
						top: "80%",
						left: "50%",
						height: "15vh",
					},
				},
			},
			psh: {
				langs: ["en"],
				types: ["n"],
				numbers: ["n1"],
				supported_devices: ["desktop", "mobile"],
				n1: {
					isPulsing: true,
					mobile: {
						top: "40%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "50%",
						left: "50%",
						height: "15vh",
					},
				},
			},
			hh: {
				langs: ["en"],
				types: ["n"],
				numbers: ["n1"],
				supported_devices: ["desktop", "mobile"],
				n1: {
					mobile: {
						top: "45%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "50%",
						left: "50%",
						height: "15vh",
					},
				},
			},
			gh: {
				langs: ["en"],
				types: ["n"],
				numbers: ["n1"],
				supported_devices: ["desktop", "mobile"],
				n1: {
					isPulsing: true,
					mobile: {
						top: "40%",
						left: "50%",
						height: "10vh",
					},
					desktop: {
						top: "50%",
						left: "50%",
						height: "15vh",
					},
				},
			}
		},
	},
};

export default lp_configs;
