import { createContext, useContext, useState, useEffect } from "react";
import { getRedirectUrl } from "__src/components/utils/helpers.js";

import {
	buildAssetPath,
	changeFaviconAndTitle,
	getUrlParams,
} from "__src/components/utils/helpers.js";
import useWindowSize from "__src/components/hooks/screensize-hook";
import { useAuth } from "__src/components/hooks/auth-hook.jsx";

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalContextProvider = ({ componentName, children }) => {
	const urlParams = getUrlParams();
	const windowSize = useWindowSize();

	changeFaviconAndTitle(urlParams.game);

	const redirectUrl = getRedirectUrl(urlParams);

	const [baseAssetPath, setBaseAssetPath] = useState(
		buildAssetPath(
			windowSize.width,
			windowSize.height,
			componentName,
			urlParams
		)
	);
	const { isLoggedin, userId, login } = useAuth();

	const contextValue = {
		...urlParams,
		baseAssetPath,
		redirectUrl,
		isLoggedin,
		userId,
		login,
	};

	useEffect(() => {
		setBaseAssetPath(
			buildAssetPath(
				windowSize.width,
				windowSize.height,
				componentName,
				urlParams
			)
		);
	}, [windowSize, componentName, urlParams]);

	return (
		<GlobalContext.Provider value={contextValue}>
			{children}
		</GlobalContext.Provider>
	);
};
