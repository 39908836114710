const redirect_urls_data = {
	games: {
		hh: {
			title: "Hentai Heroes",
			redirect_link: "https://www.hentaiheroes.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked: "https://gamingadlt.com/?lp=47&offer=47&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama"
		},
		gh: {
			title: "Gay Harem",
			redirect_link: "https://www.gayharem.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked: "https://gamingadlt.com/?lp=49&offer=49&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama"
		},
		aa: {
			title: "Amour Agent",
			redirect_link: "https://www.amouragent.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
		},
		gpsh: {
			title: "Gay Pornstars Harem",
			redirect_link: "https://www.gaypornstarharem.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked:"https://gamingadlt.com/?lp=57518&offer=5658&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama"
		},
		cxh: {
			title: "Comix Harem",
			redirect_link: "https://www.comixharem.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked: "https://gamingadlt.com/?lp=3159&offer=620&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama"
		},
		psh: {
			title: "Pornstar Harem",
			redirect_link: "https://www.pornstarharem.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked: "https://gamingadlt.com/?lp=26792&offer=2565&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama"
		},
		tpsh: {
			title: "Trans Pornstar Harem",
			redirect_link: "https://www.transpornstarharem.com/",
			mb_redirect_link: "https://www.gaming-adult.com/click",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			default_tracked:"https://gamingadlt.com/?lp=48116&offer=5020&uid=c9765958-e190-4654-b485-b388974d196d&utm_source=mediasama",
		},
		mrpg: {
			title: "Manga RPG",
			redirect_link: "https://www.mangarpg.com/",
			rt_redirect_link: "https://rd.tk.gaming-adult.com/click",
			mb_redirect_link: "https://www.gaming-adult.com/click",
		},
		cf: {
			title: "Cyber Fuck",
			redirect_link: "https://cyber-fk.net/",
		},
	},
	default_home_assets_path: "/assets/default_home/",
	ad_urls: {
		//exit pop-up
		ep: {
			//media buying
			mb: "https://rd.tk.gaming-adult.com/6733398db78ff2c6b71c8f72",
			//affiliation
			aff: "https://www.gaming-adult.com/95466fcc-41c2-4885-9999-d2ae14ad3d32",
			rt: "https://www.gaming-adult.com/157b427f-8843-4e83-a0e1-19dfc8cf18c8",
		},
		//pop under
		pu: {
			//media buying
			mb: "https://rd.tk.gaming-adult.com/673338f8e8e3db08c9f482dc",
			mb2: "https://rd.tk.gaming-adult.com/6733396be8e3db08c9f49495",
			//affiliation
			aff: "https://www.gaming-adult.com/2ed722ca-b9c2-4be7-ad5a-19d0a82bb5d9",
			rt: "https://www.gaming-adult.com/bdbeac1e-b5a0-4f51-a412-e05dfc251ea5",
		},
	},
};

export default redirect_urls_data;
