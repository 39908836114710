import "./PopUpMessage.scss";
import Button from "__src/components/Button/Button";
import TextBlock from "__src/components/TextBlock/TextBlock";
import { useGlobalContext } from "__src/components/store/GlobalContext.jsx";

import allTexts from "./texts.json";

const PopUpMessage = ({ onClick, redirectUrl }) => {
	const { lang } = useGlobalContext();

	const handleClose = () => {
		onClick(false);
	};

	const texts = allTexts[lang] ?? allTexts["en"];
	const styleButtons = {
		backgroundColor: "#ff6347",
		color: "#fff",
		padding: "10px 20px",
		borderRadius: "5px",
		border: "none",
	};

	return (
		<div
			className={"box"}
			style={{
				backgroundColor: "#fff",
				border: "2px solid #000",
				padding: "20px",
				textAlign: "center",
				borderRadius: "10px",
			}}
		>
			<TextBlock
				fontSize={"48px"}
				textValue={texts.text}
				style={{
					fontSize: "24px",
					color: "#000",
				}}
			/>
			<Button
				text={texts.redirectButtonText}
				url={redirectUrl}
				className={"button"}
				style={styleButtons}
			/>
			<button onClick={handleClose} className={"button"} style={styleButtons}>
				{texts.closeButtonText}
			</button>
		</div>
	);
};

export default PopUpMessage;
