import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DynamicComponentDelivery from "./pages/DynamicComponentDelivery.jsx";

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<DynamicComponentDelivery />} />
				<Route
					path="/:componentName"
					element={<DynamicComponentDelivery />}
				/>
			</Routes>
		</Router>
	);
};

export default App;
