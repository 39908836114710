import React, { useState, useEffect } from "react";
import BackDrop from "__src/components/ui/BackDrop.jsx";
import PopUpMessage from "./PopUpMessage";
import { useGlobalContext } from "__src/components/store/GlobalContext.jsx";
import useWindowSize from "__src/components/hooks/screensize-hook";
import { getAdUrl } from "../../utils/helpers.js";

const ExitPopUp = () => {
	const { ep, st } = useGlobalContext();

	const [isVisible, setIsVisible] = useState(false);
	const { width, height } = useWindowSize();

	useEffect(() => {
		const handleMouseOut = (event) => {
			if (event.clientY <= 0 || event.clientY >= height) {
				setIsVisible(true);
			}

			if (event.clientX >= width || event.clientX <= 0) {
				setIsVisible(true);
			}
		};

		document.addEventListener("mouseout", handleMouseOut);

		return () => {
			document.removeEventListener("mouseout", handleMouseOut);
		};
	}, [width, height]);

	const handleClose = () => {
		setIsVisible(false);
	};

	return (
		ep > 0 &&
		isVisible && (
			<BackDrop onClick={handleClose}>
				<PopUpMessage
					onClick={handleClose}
					redirectUrl={getAdUrl("ep", st)}
				></PopUpMessage>
			</BackDrop>
		)
	);
};

export default ExitPopUp;
