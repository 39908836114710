import React from "react";
import "./Button.scss";
import { redirectWithAd } from "../utils/helpers.js";

const Button = ({
	text,
	url,
	backgroundColor,
	type,
	imgSrc,
	imgAlt,
	style,
	className = "",
	buttonClassName = "",
	has_pop_under = "0",
	st,
	imgStyle,
}) => {
	const cName = "custom-button" + " " + className;
	const button = "button-image" + " " + buttonClassName;
	return (
		<div
			className={cName}
			style={{
				...style,
				backgroundColor: backgroundColor,
			}}
			onClick={
				has_pop_under === "1"
					? () => redirectWithAd(url, st)
					: () => (window.location.href = url)
			}
		>
			{type === "image" && imgSrc ? (
				<img src={imgSrc} alt={imgAlt} className={button} style={imgStyle} />
			) : (
				<span>{text}</span>
			)}
		</div>
	);
};

export default Button;
